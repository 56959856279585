import React, { useRef } from "react"
import CustomHTMLParser from "components/CustomHTMLParser"
import CountdownNow from "react-countdown-now"
import Link from "components/Link"

import { ParagraphStyled, LinkStyled, CountdownTextStyled } from "../styles"

function getUnit(number, unit, lang) {
  if (lang === "en") {
    switch (unit) {
      case "days":
        return number === 0 || number === 1 ? "day" : "days"
      case "hours":
        return number === 1 ? "h" : "h"
      case "minutes":
        return number === 1 ? "min" : "mins"
      case "seconds":
        return number === 1 ? "sec" : "sec"
    }
  } else {
    switch (unit) {
      case "days":
        return number === 0 || number === 1 ? "Tag" : "Tage"
      case "hours":
        return number === 1 ? "Std" : "Std"
      case "minutes":
        return number === 1 ? "Min" : "Min"
      case "seconds":
        return number === 1 ? "Sek" : "Sek"
    }
  }
}

const CustomP = ({ children, showTextCountdown, countdownDateTime, lang }) => {
  // const timeStrings = getTimeStrings(time)
  const countdown = useRef()
  const date = new Date(Number(countdownDateTime) * 1e3)

  return (
    <ParagraphStyled>
      <span>{children}</span>
      {showTextCountdown && (
        <CountdownNow
          date={date}
          ref={countdown}
          renderer={({ days, hours, minutes, seconds }) => (
            <CountdownTextStyled>
              <span> {lang === "en" ? "Only" : "Nur noch"} </span>
              <span>
                {String(days)} {getUnit(days, "days", lang)},{" "}
              </span>
              <span>
                {String(hours)} {getUnit(hours, "hours", lang)},{" "}
              </span>
              <span>
                {String(minutes)} {getUnit(minutes, "minutes", lang)}{" "}
                {lang === "en" ? "and" : "und"}{" "}
              </span>
              <span>
                {String(seconds)} {getUnit(seconds, "seconds", lang)}
              </span>
              {lang === "en" ? " left." : "."}
            </CountdownTextStyled>
          )}
        />
      )}
    </ParagraphStyled>
  )
}

const CustomA = ({ children, href }) => {
  return href ? <LinkStyled to={href}>{children}</LinkStyled> : null
}

const PopupText = ({
  children,
  htmlText,
  showTextCountdown,
  stretch,
  layout,
  position,
  countdownDateTime,
  pageContext,
}) => {
  const lang = pageContext.language === "en"
  const containsLink = htmlText.includes('<a href="')

  // if a link is added in the redactor, cut the cms url prefix from it
  const parsedRedactorText = htmlText
    ? htmlText.replace("https://cms.evofitness.de", "")
    : ""

  const customComponents = [
    {
      name: "p",
      Component: CustomP,
      // pass props to this component
      props: {
        showTextCountdown,
        countdownDateTime,
        lang,
      },
    },
    {
      name: "a",
      Component: CustomA,
    },
  ]
  /*
    CustomHTMLParser creates react components.
    Injects(creates) custom components defined in 'customComponents'.
  */
  return (
    <div>
      {/* creates react components from html code */}
      <CustomHTMLParser
        html={parsedRedactorText}
        customComponents={[...customComponents]}
      />
      {children}
    </div>
  )
}
export default PopupText
