import media from "utils/mediaqueries"
import { styled } from "utils/emotion"
import Link from "components/Link"

export const PopupContainerStyled = styled("div")(
  {
    background: "rgba(0,0,0,0.5)",
    position: "fixed",
    zIndex: 2147483647,
    margin: "0 auto",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    transition: "opacity 150ms ease-out",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  (props) => ({
    opacity: props.show === false ? 0 : 1,
    pointerEvents: props.show === false ? "none" : "all",
  })
)

export const PopupGridStyled = styled("div")(
  {
    transition: "transform 150ms ease-out",
    justifyContent: "center",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    maxWidth: "50vw",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.3)",

    [media(0, "tablet")]: {
      maxWidth: "100vw",
    },
  },
  (props) => ({
    transform: props.show === false ? "translateY(40px)" : "translateY(0px)",
    background:
      props.layout === "green"
        ? props.theme.popupOfferContentBackground
        : "white",
  })
)

export const PopupTextContainerStyled = styled("div")({
  textAlign: "center",
  padding: "50px 50px 70px",
  maxWidth: 800,
})

export const PopupCloseButtonCrossStyled = styled("div")({
  position: "absolute",
  top: 20,
  right: 20,
  transition: "transform 100ms ease-out",
  transformOrigin: "center center",
  lineHeight: "0",
  cursor: "pointer",

  "&:hover": {
    transform: "rotate(90deg)",
  },
})

export const PopupCloseWrapperStyled = styled("div")({
  minWidth: 110,
  minHeight: 60,
  display: "flex",
  transition: "transform 100ms ease-out",

  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.3)",
  },
})

export const LinkStyled = styled(Link)(
  {
    zIndex: 15,
    ":hover": {
      textDecoration: "underline",
    },
  },
  (props) => ({
    // color: props.theme.colorTertiary,
  })
)

export const ParagraphStyled = styled("p")({
  fontSize: "1rem",
  color: "black",
  fontWeight: 600,
  letterSpacing: "1px",
  marginTop: 20,
})

export const PopupCountdownWrapper = styled("div")(
  {
    // ------------------
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    position: "relative",
    // ------------------
    // controls height of banner type 2
    padding: "10px",
    minWidth: 170,

    [media(0, "tablet")]: {
      width: "100%",
      justifyContent: "center",
    },
  }
  // (props) => ({
  //   backgroundColor:
  //     props.layout === "green" ? "rgb(2,119,116)" : props.theme.colorTertiary,
  // })
)

export const PopupCountdownUnit = styled("div")({
  fontWeight: 400,
  fontSize: "1rem",
})

export const PopupCountdownTimeWrapper = styled("div")({
  textAlign: "center",
  fontWeight: 600,
  fontSize: "2rem",
  width: 50,
  margin: "0px 30px 20px",
  [media(0, 300)]: {
    fontSize: "1rem",
    padding: "0px",
  },
})

export const PopupCountdownDots = styled("div")({
  top: "15px",
  position: "relative",
})

export const CountdownTextStyled = styled("span")({
  fontSize: 13,
  fontWeight: 500,
  opacity: 0.7,
})
