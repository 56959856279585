import React, { useRef } from "react"
import CountdownNow from "react-countdown-now"

import {
  PopupCountdownWrapper,
  PopupCountdownUnit,
  PopupCountdownTimeWrapper,
  PopupCountdownDots,
} from "../styles"

const PopupCountdown = ({ countdownDateTime, layout }) => {
  const countdown = useRef()
  const date = new Date(Number(countdownDateTime) * 1e3)

  return (
    <PopupCountdownWrapper layout={layout}>
      <CountdownNow
        date={date}
        ref={countdown}
        renderer={({ days, hours, minutes, seconds }) => (
          <div
            style={{
              display: "inline-flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <PopupCountdownTimeWrapper>
              <div>{String(days).padStart(2, "0")}</div>
              <PopupCountdownUnit>TAGE</PopupCountdownUnit>
            </PopupCountdownTimeWrapper>

            {/* <PopupCountdownDots>:</PopupCountdownDots> */}
            <PopupCountdownTimeWrapper>
              <div>{String(hours).padStart(2, "0")}</div>
              <PopupCountdownUnit>STD</PopupCountdownUnit>
            </PopupCountdownTimeWrapper>

            {/* <PopupCountdownDots>:</PopupCountdownDots> */}
            <PopupCountdownTimeWrapper>
              <div>{String(minutes).padStart(2, "0")}</div>
              <PopupCountdownUnit>MIN</PopupCountdownUnit>
            </PopupCountdownTimeWrapper>

            {/* <PopupCountdownDots>:</PopupCountdownDots> */}
            <PopupCountdownTimeWrapper>
              <div>{String(seconds).padStart(2, "0")}</div>
              <PopupCountdownUnit>SEK</PopupCountdownUnit>
            </PopupCountdownTimeWrapper>
          </div>
        )}
      />
    </PopupCountdownWrapper>
  )
}
export default PopupCountdown
