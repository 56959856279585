import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import useLocalStorage from 'utils/hooks/useLocalStorage'
import md5 from 'tiny-hashes/md5'
import useTimeDifference from 'utils/useTimeDifference'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { useScrollPosition } from 'utils/hooks'

import PopupText from './components/PopupText'
import PopupCloseButton from './components/PopupCloseButton'
import PopupCountdown from './components/PopupCountdown'

export default function Popup({ pageContext }) {
  let globalPopup = null

  if (pageContext) {
    const staticData = useStaticQuery(graphql`
      query PopupGlobalsStatic {
        craft {
          # Globals
          globalsDe: globals(siteId: 1) {
            ...GlobalPopupQuery
          }

          globalsEn: globals(siteId: 2) {
            ...GlobalPopupQuery
          }
        }
      }
    `)

    const { globalsDe, globalsEn } = staticData?.craft

    const globals = pageContext.siteId === 1 ? globalsDe : globalsEn
    globalPopup = { ...globals.popup }
  }

  const date = new Date()
  const dateString =
    date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate()

  const popupTextHash = md5(globalPopup?.offerPopupText?.content || '')

  const [globalPopupStorageHidden, setGlobalPopupStorageHidden] =
    useLocalStorage(`globalPopupHidden-${popupTextHash}-${dateString}`, false)

  const [globalPopupHidden, hideGlobalPopup] = useState(true)

  useEffect(() => {
    hideGlobalPopup(globalPopupStorageHidden === true)
  }, [globalPopupStorageHidden])

  const handleGlobalPopupHide = () => {
    setGlobalPopupStorageHidden(true)
    hideGlobalPopup(true)
  }

  return (
    <div>
      {/* if offerPopupText field is set on current page show page popup */}
      {globalPopup && globalPopup?.offerPopupText ? (
        <GlobalPopup
          visible={!globalPopupHidden}
          onHide={() => handleGlobalPopupHide()}
          //   currentPage={curPageTitle}
          pageContext={pageContext}
          {...globalPopup}
        />
      ) : null}
    </div>
  )
}

function GlobalPopup({
  bannerSiteSelection,
  offerPopupExpirationDate,
  offerPopupAppearTimeout,
  offerPopupAppearScrollPosition,
  offerPopupText,
  offerPopupButton,
  onHide,
  pageContext,
  visible,
}) {
  const pages = bannerSiteSelection.map(({ id }) => id) // flat array
  const visibleOnAllPages = pages.length === 0
  const visibleOnCurrentPage = pages.includes(pageContext.id)
  const textContent = offerPopupText ? offerPopupText.content : ''

  return visibleOnCurrentPage || visibleOnAllPages ? (
    <PagePopup
      visible={visible}
      onHide={onHide}
      offerPopupButton={offerPopupButton}
      textContent={textContent}
      offerPopupExpirationDate={offerPopupExpirationDate}
      offerPopupAppearTimeout={offerPopupAppearTimeout}
      offerPopupAppearScrollPosition={offerPopupAppearScrollPosition}
      pageContext={pageContext}
    />
  ) : null
}

export const query = graphql`
  fragment GlobalPopupQuery on Craft_GlobalsSet {
    popup {
      offerPopupAppearTimeout
      offerPopupExpirationDate
      offerPopupAppearScrollPosition
      bannerSiteSelection {
        id
        title
        fullUri
      }
      offerPopupButton {
        entry {
          fullUri
        }
        url
        text
      }
      offerPopupText {
        content
      }
    }
  }
`

import {
  PopupGridStyled,
  PopupCloseWrapperStyled,
  PopupContainerStyled,
  PopupTextContainerStyled,
  PopupCloseButtonCrossStyled,
} from './styles'

function PagePopup({
  offerPopupButton,
  offerPopupExpirationDate,
  onHide,
  visible,
  pageContext,
  textContent,
  offerPopupAppearTimeout,
  offerPopupAppearScrollPosition,
}) {
  // const offerPopupExpirationDate = new Date() * .001 + (1 * 5); // Test Time 5 Sek
  const time = useTimeDifference(offerPopupExpirationDate)

  const [showAfterSeconds, setShowAfterSeconds] = useState(
    offerPopupAppearTimeout ? false : true
  )

  const [showAfterScrollPos, setShowAfterScrollPos] = useState(
    offerPopupAppearScrollPosition ? false : true
  )

  const scrollpos = useScrollPosition()

  useEffect(() => {
    const positionToReach =
      ((document.body.scrollHeight - window.innerHeight) *
        offerPopupAppearScrollPosition) /
      100
    if (scrollpos >= positionToReach) {
      setShowAfterScrollPos(true)
    }
  }, [scrollpos])

  const hasTimeDiff = time > 0

  useEffect(() => {
    setTimeout(() => {
      setShowAfterSeconds(true)
    }, offerPopupAppearTimeout * 1000)
  }, [])

  const layout = 'green'

  const triggered =
    offerPopupAppearScrollPosition && offerPopupAppearTimeout
      ? showAfterScrollPos || showAfterSeconds // when both defined either trigger shows popup
      : showAfterScrollPos && showAfterSeconds // when only one is defined or none, it needs both triggers, since the default value if not set is true in useState

  const popupIsVisible =
    triggered && visible && (hasTimeDiff || offerPopupExpirationDate === null)

  return (
    <PopupContainerStyled layout={layout} show={popupIsVisible}>
      <PopupGridStyled layout={layout} show={popupIsVisible}>
        <PopupCloseButtonCrossStyled onClick={() => (onHide ? onHide() : null)}>
          <Icon type="closeBig" />
        </PopupCloseButtonCrossStyled>
        <PopupTextContainerStyled layout={layout}>
          {offerPopupExpirationDate && (
            <PopupCountdown
              countdownDateTime={offerPopupExpirationDate}
              layout={layout}
            />
          )}

          <PopupText
            layout={layout}
            htmlText={textContent}
            //   showTextCountdown={showTextCountdown}
            //   countdownDateTime={offerPopupExpirationDate}
            pageContext={pageContext}
          />
          {offerPopupButton && (
            <Button
              onClick={() => (onHide ? onHide() : null)}
              margin="30px 0 0"
              to={
                offerPopupButton?.entry?.fullUri || offerPopupButton?.url || ''
              }>
              {offerPopupButton.text}
            </Button>
          )}
        </PopupTextContainerStyled>
        {/* <PopupCloseWrapperStyled>
          <PopupCloseButton onClick={() => (onHide ? onHide() : null)} />
        </PopupCloseWrapperStyled> */}
      </PopupGridStyled>
    </PopupContainerStyled>
  )
}
